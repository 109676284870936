import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { BIG_NUMBER_SUFFIX_REGEX, NumberUtil } from '@dextools/utils';

/**
 * @description Transforms a large number or string by rounding it down to a more readable format (like "K" for thousand).
 */
@Pipe({
  name: 'appRoundBigNumber',
  standalone: true,
})
export class RoundBigNumberPipe implements PipeTransform {
  /**
   * Transforms a large number or string by rounding it down to a more readable format.
   * It first checks if the rounded number matches the regex for large numbers with suffixes (like "K" for thousand).
   * If it does, it returns the rounded number with the suffix.
   * Otherwise, it returns a normally rounded number.
   *
   * @param value - The number or string to be rounded.
   * @returns A formatted string with either the large rounded number or a decently rounded regular number.
   */
  public transform(value: number | string = '', maxDecimals?: number, removeZeros = false): string {
    const roundedNumber = NumberUtil.roundBigNumber(value, maxDecimals, removeZeros);

    if (BIG_NUMBER_SUFFIX_REGEX.test(roundedNumber)) {
      return roundedNumber;
    }

    // in case the number was not large enough to be rounded, we return a decent rounded number
    return NumberUtil.transformNumber(Number(roundedNumber), undefined, maxDecimals);
  }
}
